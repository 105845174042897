import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer" style={{"height": "509.006px"}}>
        <div className="row">
          <div class="three columns" >
                    <ul>
              <li className="first last">
                <a href="/fr/comment-agit-imodium/">Comment agit IMODIUM®?</a>
                <ul>
                  <li className="first last">
                    <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                      La substance active : lopéramide
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className="parent-here first last">
                <a href="/fr/medicaments-imodium/">Médicaments IMODIUM®</a>
                <ul>
                  <li className="first">
                    <a href="/fr/medicaments-imodium/imodium-instant/">
                      IMODIUM® Instant
                    </a>
                  </li>
                  <li>
                    <a href="/fr/medicaments-imodium/imodium-duo/">
                      IMODIUM® Duo
                    </a>
                  </li>
                  <li>
                    <a href="/fr/medicaments-imodium/imodium-capsules/">
                      IMODIUM® Capsules
                    </a>
                  </li>
                  
                </ul>
              </li>
            </ul>
          </div>
          <div class="three columns" >
                    <ul>
              <li className="first last">
                <a href="/fr/la-diarrhee/">En savoir plus sur la diarrhée</a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                      Conseils pour le traitement des symptomes de la diarrhée
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                      Causes de la diarrhée
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                      Stress et diarrhée
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                      Infection virale ou bactérienne
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                      La diarrhée pendant les règles
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                      La diarrhée chronique
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                      Consulter un médecin?
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                      Des habitudes alimentaires saines
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="three columns" >
                    <ul></ul>
            <ul>
              <li className="first last">
                <a href="/fr/diarrhee-en-voyage/">Diarrhée en voyage</a>
                <ul>
                  <li className="first">
                    <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                      Eviter la diarrhée du voyageur
                    </a>
                  </li>
                  <li>
                    <a href="/fr/diarrhee-en-voyage/traitement-de-la-diarrhee-du-voyageur/">
                      Traitement de la diarrhée du voyageur
                    </a>
                  </li>
                  <li>
                    <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                      Pharmacie de voyage
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="three columns" >
                    <ul>
              <li className="first">
                <a href="/fr/diarrhee-chez-les-enfants/">
                  Diarrhée chez les enfants
                </a>
              </li>              
              <li>
                <a href="/fr/lexique/">Lexique</a>
              </li>
              <li className="last">
                <a href="/fr/faq-questions-diarrhee/">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <nav className="nav">
              <ol className="uilist-hor">
                <li className="first">
                  <a href="/fr/plan-du-site/">Plan du site</a>
                </li>
                <li>
                  <a href="/fr/mentions-legales/">Mentions légales</a>
                </li>
                <li>
                  <a href="/fr/respect-de-la-vie-privee/">
                    Respect de la vie privée
                  </a>
                </li>
                <li>
                  <a href="/fr/politique-en-matiere-de-cookies/">
                    Utilisation des cookies
                  </a>
                </li>
                <li className="last">
                  <a href="/fr/contact/">Contact</a>
                </li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Paramètres des cookies</a>
              </ol>
            </nav>
          </div>
        </div>
        <div className="disclaimer">
          <div className="row">
            <div className="twelve columns">
              <p>
                © Johnson &amp; Johnson Consumer S.A. 2017, TVA BE 0401.955.033
                - RPM Anvers, 2017. Ce site est entretenu par Johnson &amp;
                Johnson Consumer S.A. dont la responsabilité se restreint à son
                seul contenu. Ce site est destiné au public de la Belgique et au
                Grand duché de Luxembourg. Last updated: Thursday, April 22, 
				2021 
              </p>
              <p>
                IMODIUM<sup>®</sup> Instant / Capsules (lopéramide) est un
                médicament. Ne pas administrer à des enfants de moins de 6 ans.
                Ne pas utiliser plus de 2 jours sans avis médical.
                <br />
                IMODIUM<sup>®</sup> Duo (lopéramide, siméticone) est un
                médicament. Ne pas administrer à des enfants de moins de 12 ans.
                Pas d'utilisation pendant plus de 2 jours sans avis médical.
                <br />
                Lisez attentivement la notice. Demandez conseil à votre médecin
                ou votre pharmacien. Licence: Johnson &amp; Johnson Consumer
                S.A. Michel de Braeystraat 52, 2000 Antwerpen, Belgique. Nr de Dossier:
                BE-IM-2200031.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
